
import { defineComponent, reactive, ref } from 'vue'
import Person from '@/components/vectors/Person.vue'
import AlertIcon from '@/components/vectors/AlertIcon.vue'
import useVuelidate from '@vuelidate/core'
import { required, email, minLength, helpers } from '@vuelidate/validators'
import useSnackbar from '@/composables/useSnackbar'
import { useRouter } from 'vue-router'
import useSessions from '@/composables/useSessions'
import Spinner from '@/components/Spinner.vue'
import TandCsModal from '@/components/TandCsModal.vue'
import Input from '@/components/Input.vue'
import GenericError from '@/components/GenericError.vue'
import { sendExponeaCustomerIdentificationEvent } from '@/helpers/exponeaHelper'
import { getBuyerDetails } from '@/expressway-api/auth.api'

export default defineComponent({
  components: {
    Person,
    AlertIcon,
    Spinner,
    TandCsModal,
    'v-input': Input,
    GenericError
  },
  setup () {
    const { setSnackbar } = useSnackbar()
    const router = useRouter()
    const { logIn } = useSessions()
    const showTandCsModal = ref(false)
    const tandCsError = ref(false)
    const loadingLoginCall = ref(false)

    const state = reactive({
      email: '',
      password: ''
    })

    const rules = {
      email: {
        required,
        email: helpers.withMessage('Please enter a valid Email Address', email)
      },
      password: {
        required: helpers.withMessage('Please enter password', required),
        minLength: helpers.withMessage('Password must be at least 8 characters long.', minLength(8))
      }
    }

    const loginCall = () => {
      loadingLoginCall.value = true

      logIn({ Username: state.email, Password: state.password })
        .then(response => {
          loadingLoginCall.value = false
          if (response?.AcceptedTermsConditions) {
            router.push({ name: 'MyAccount' })
          } else {
            showTandCsModal.value = true
          }
          getBuyerDetails().then(res => {
            sendExponeaCustomerIdentificationEvent(state.email, res.Id)
          })
        })
        .catch((error: any) => {
          loadingLoginCall.value = false
          setSnackbar(error.message || 'Error attempting to log in', 'danger')
        })
    }

    return {
      v$: useVuelidate(rules, state),
      loginCall,
      showTandCsModal,
      tandCsError,
      loadingLoginCall
    }
  }
})
