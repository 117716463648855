export function loadRecaptcha (siteKey) {
  return new Promise((resolve, reject) => {
    if (document.getElementById('recaptcha-script')) {
      resolve(undefined)
      return
    }

    const script = document.createElement('script')
    script.id = 'recaptcha-script'
    script.src = `https://www.google.com/recaptcha/api.js?render=${siteKey}`
    script.async = true
    script.defer = true
    script.onload = resolve
    script.onerror = reject
    document.head.appendChild(script)
  })
}

export function unloadRecaptcha () {
  const script = document.getElementById('recaptcha-script')
  if (script) {
    document.head.removeChild(script)
  }
}
